import React, {FC, memo} from 'react'
import {IHomeTitleProps} from '@modules/Home/home.types'
import {useTranslation} from '@common/hooks/useTranslation'
import {ITranslation} from '@common/types'
import {scrollToElementById} from '@common/utils/scrollToElementById'
import Text from '@common/components/common/Text'

const HomeTitle: FC<IHomeTitleProps> = ({title, statistics}) => {
  const t = useTranslation('home') as ITranslation['home']

  return (
    <section className="hero">
      <div className="container">
        <h1 className="hero__title">
          <Text text={title} />
        </h1>

        <ul className="hero__list">
          <li className="hero__item">
            <a className="hero__achievements" onClick={() => scrollToElementById('main-projects')}>
              <span className="hero__number">{statistics?.projectTitle}</span>
              <span className="hero__underline">{t.hero.projects}</span>
            </a>
          </li>
          <li className="hero__item">
            <a className="hero__achievements" onClick={() => scrollToElementById('main-products')}>
              <span className="hero__number">{statistics?.experienceTitle}</span>
              <span className="hero__underline">{t.hero.years}</span>
            </a>
          </li>
          <li className="hero__item">
            <a className="hero__achievements" onClick={() => scrollToElementById('main-awards')}>
              <span className="hero__number">{statistics?.ratingTitle}</span>
              <span className="hero__underline">{t.hero.rating}</span>
            </a>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default memo(HomeTitle)
